/** @jsx jsx */
import { Styled, jsx } from 'theme-ui';
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import 'normalize.css';

import Header from './header';

const Layout = ({ children, disableHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Styled.root>
      {!disableHeader && <Header siteTitle={data.site.siteMetadata.title} />}

      <div
        sx={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: 3,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer
          sx={{
            textAlign: 'center',
          }}
        >
          <Styled.p>© {new Date().getFullYear()}, Aleksi Leino</Styled.p>
        </footer>
      </div>
    </Styled.root>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
