/** @jsx jsx */
import { jsx, Flex, Box, Text } from 'theme-ui';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(faClipboard, fab);

const iconColor = '#888';

const headerLinks = [
  {
    text: '',
    icon: 'clipboard',
    link: 'https://notes.leino.io',
  },
  {
    text: '',
    icon: ['fab', 'linkedin-in'],
    link: 'https://www.linkedin.com/in/aleksileino/',
  },
  {
    text: '',
    icon: ['fab', 'gitlab'],
    link: 'https://gitlab.com/aleino',
  },
];

const HeaderLink = ({ text, icon, link }) => (
  <Flex
    sx={{
      flexDirection: 'row',
      margin: 2,
      color: iconColor,
    }}
  >
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Text
        sx={{
          flexDirection: 'row',
          margin: 2,
        }}
      >
        {text}
        <FontAwesomeIcon icon={icon} color={iconColor} size="lg" margin={2} />
      </Text>
    </a>
  </Flex>
);

const Header = ({ disableHome, disableBottomMargin }) => {
  const marginBottom = !disableBottomMargin ? 4 : 0;
  return (
    <Flex
      as="header"
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 4,
        height: '72px',
        background: `muted`,
        marginBottom,
        borderBottomWidth: '1px',
        borderBottomColor: '#dedede',
        borderBottomStyle: 'solid',
      }}
    >
      <Box>{!disableHome && <a href="/">Home</a>}</Box>
      <Flex>
        {headerLinks.map(hl => (
          <HeaderLink {...hl} />
        ))}
      </Flex>
    </Flex>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
